import { createI18n } from "vue-i18n"
import deepmerge from "deepmerge"

import de from "./de.json"
import en from "./en.json"
import es from "./es.json"
import hu from "./hu.json"
import deIntegrations from "./integrations/de.json"
import enIntegrations from "./integrations/en.json"
import esIntegrations from "./integrations/es.json"
import huIntegrations from "./integrations/hu.json"

const messages = {
  en: deepmerge.all([en, enIntegrations], { clone: false }),
  de: deepmerge.all([de, deIntegrations], { clone: false }),
  hu: deepmerge.all([hu, huIntegrations], { clone: false }),
  es: deepmerge.all([es, esIntegrations], { clone: false }),
}

const fallbackLocale = {
  en: ["de"],
  hu: ["en", "de", "hu"],
  es: ["en", "de", "es"],
}

if (location.href.includes("translate=1")) {
  const ach = Object.values<any>(import.meta.glob("./ach.json", { eager: true }))[0].default
  Object.assign(messages, { ach })
  Object.assign(fallbackLocale, { ach: ["en", "de"] })
}

const i18n = createI18n({
  locale: "de", // set locale
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  messages: messages as any, // set locale messages
  fallbackLocale: fallbackLocale,
  missing: (locale, key, vm) => {
    if (key.includes(".customPropertyColumns.")) {
      return i18n.global.t(key.replace(".customPropertyColumns.", ".formFields."))
    }
  },
})

// higher order function to create prefixed i18n lookup
export const createPrefixer = (prefix: string) => (key: string) => i18n.global.t(`${prefix}.${key}`)

export const { t } = i18n.global
export default i18n
